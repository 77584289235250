//Basic setup

* { box-sizing: border-box; }
html { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; min-height:100%; }

body {
  margin:0;
  color: $colorText;
  @extend %typeNormal;
}

h1, h2, h3, h4, h5, h6, p, small, li { font-size: 1em; display: block; margin: 0; font-weight: normal; }
em { font-size: 1em; font-weight: normal; }
ul, ol { list-style: none; list-style-image: none; margin: 0; padding: 0; }
img { border: 0; vertical-align: middle; display:inline-block; max-width: 100%; }
figure { margin: 0; }
a, input, button { -ms-touch-action: none !important; }
input, textarea { -webkit-appearance: none; -webkit-border-radius: 0px; /* outline: none; */ }
textarea { resize: none; /* outline: none; */ }
select { /*-webkit-appearance: normal;*/ -webkit-border-radius: 0px; /* outline: none; */ }
input:-webkit-autofill { -webkit-box-shadow: 0 0 0px 1000px transparent inset; } /* change to default color */
input::-ms-clear { display: none; }
blockquote, address, cite { font-style: normal; }

b,
strong {
  font-weight:500;
}

a { color: $colorText; text-decoration: none; transition: color 0.2s ease-out; }
a:hover { color: $colorText; }
a:focus { /*outline: thin dotted;*/ }
a:hover, a:active { outline: 0; }

button:not(:focus) { outline: 0; }

.using-mouse {
  button, a { outline: none !important; }
}

// Placeholder text styling
::-webkit-input-placeholder {
  color: $colorText;
}
:-moz-placeholder {
 color: $colorText;
}
::-moz-placeholder {
 color: $colorText;
}
:-ms-input-placeholder {
 color: $colorText;
}

.sr-only {
 display: block;
 position: absolute;
 overflow: hidden;
 width: 1px;
 height: 1px;
 clip: rect(0 0 0 0);
 border: 0;
 padding: 0;
 margin: 0 -1px -1px 0;
}
