.page {

}

.page-hero {
  @include spacing(40,80,100,110,7);
  &.-has-image {
    position: relative;
  }
  &__text {
    @extend %bodyGutter;
    text-align: center;
    width: 100%;
    color: #fff;
    &.-dark {
      color: $black;
    }
    .-has-image & {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    h1 {
      @extend %headingXLalt;
      max-width: 800px;
      margin: 0 auto;
    }
  }
}

.page-intro {
  @extend %bodyGutter;
  @include spacing(40,80,100,110,7);
  p {
    @extend %heading;
    line-height: 1.25;
    text-align: center;
    font-size: 18px;
    max-width: 690px;
    margin: 0 auto;
    @include mqmed {
      font-size: 26px;
    }
    @include mqbig {
      font-size: 32px;
    }
    @include mqhuge {
      font-size: 37px;
      max-width: 780px;
    }
  }
}

.page-boxes {
  padding: 0 $bodyGutterSmall;
  @include spacing(40,80,100,110,40);
  @include mqmed {
    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; }
    *zoom: 1;
    padding: 0 $bodyGutterMed - $gridGutterMed/2;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  @include mqbig {
    padding: 0 $bodyGutterBig - $gridGutterBig/2;
  }
  @include mqhuge {
    padding: 0 $bodyGutterHuge - $gridGutterHuge/2;
  }

  &__box-wrap {
    position: relative;
    margin-bottom: 30px;
    padding: 40px 0 0;
    @include mqmed {
      padding: 40px $gridGutterMed/2 0;
      float: left;
      width: 50%;
      flex: 0 0 50%;
      display: flex;
      margin-bottom: 40px;
    }
    @include mqbig {
      padding: 40px $gridGutterBig/2 0;
      width: 25%;
      flex: 0 0 25%;
    }
    @include mqhuge {
      padding: 40px $gridGutterHuge/2 0;
    }
    &:nth-child(2n + 3) {
      @include mqmed {
        clear: both;
      }
      @include mqbig {
        clear: none;
      }
    }
  }
  &__box {
    border: 1px solid $black;
    padding: 30px;
    @include mqmed {
      flex: 0 0 100%;
    }
    @include mqbig {
      padding: 35px;
    }
  }
  &__label {
    font-size: 12px;
    line-height: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    @include mqbig {
      font-size: 15px;
    }
    @include mqhuge {
      font-size: 18px;
    }
  }
  &__heading {
    @extend %heading;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 20px;
    @include mqmed {
      font-size: 23px;
      min-height: 54px;
    }
    @include mqhuge {
      font-size: 29px;
      margin-bottom: 35px;
    }
  }
  &__text {
    font-size: 15px;
    line-height: (27/15);
    @include mqbigish {
      font-size: 17px;
      line-height: (30/18);
    }
    @include mqhuge {
      font-size: 21px;
      line-height: (33/21);
    }
  }
}

.page-image {
  @include spacing(40,80,100,110);
  img {
    width: 100%;
    height: auto;
  }
  &.-single {
    width: 100%;
    overflow: hidden;
    &:last-child {
      margin-bottom: 50px;
      @include mqmed {
        margin-bottom: 0;
      }
    }
  }
  &.-double {
    padding: 0 $bodyGutterSmall;
    @extend %clearfix;
    @include mqmed {
      padding: 0 $bodyGutterMed - $gridGutterMed/2;
    }
    @include mqbig {
      padding: 0 $bodyGutterBig - $gridGutterBig/2;
    }
    @include mqhuge {
      padding: 0 $bodyGutterHuge - $gridGutterHuge/2;
    }
  }
  &__image {
    &.-double {
      width: 100%;
      @include mqmed {
        float: left;
        width: 50%;
        padding: 0 $gridGutterMed/2;
      }
      @include mqbig {
        padding: 0 $gridGutterBig/2;
      }
      @include mqhuge {
        padding: 0 $gridGutterHuge/2;
      }
      &:first-child {
        margin-bottom: 40px;
        @include mqmed {
          margin-bottom: 0;
        }
      }
    }
  }
}
