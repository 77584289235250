.article {
    img {
        height: auto;
    }

    &__hero {
        color: $black;
        text-align: center;
        margin-top: 15px;
        @include mqmed {
            margin-top: 35px;
        }
        @include mqbig {
            margin-top: 44px;
        }
        @include mqhuge {
            margin-top: 55px;
        }

        &.-text-over {
            margin-top: 0;
        }

        &.-contained {
            @extend %bodyGutter;
        }
    }

    &__hero-wrap {
        position: relative;
        @include spacing(13, 18, 18, 23);

        .-text-over & {
            @include spacing(20, 35, 45, 55);
        }

        &:last-child {
            @include spacing(45, 74, 95, 104);
        }

        .-light & {
            color: #fff;
        }
    }

    &__hero-sub-text {
        @include spacing(38, 67, 88, 97);
    }

    &__hero-text {
        margin-bottom: 25px;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
        @include mqmed {
            margin-bottom: 50px;
        }
        @include mqbig {
            margin-bottom: 80px;
        }

        &.-contained {
            @extend %bodyGutter;
        }

        .-text-over & {
            width: 100%;
            padding: 0 20px;
            margin-bottom: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .-text-over.-full-bleed & {
            @extend %bodyGutter;
        }
    }

    &__hero-heading {
        @extend %headingXLalt;

        &:not(:last-child) {
            margin-bottom: 25px;
            @include mqmed {
                margin-bottom: 45px;
            }
        }
    }

    &__hero-caption {
        @extend %bodyText;
    }

    &__hero-credit {
        display: block;

        .-text-over & {
            display: none;
            @include mqmed {
                display: block;
                position: absolute;
                bottom: 24px;
                left: 20px;
            }
        }
    }

    &__hero-embed {
        position: relative;
        width: 100%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__category {
        @extend %bodyText;
        display: block;
        margin-bottom: 25px;
        @include mqmed {
            margin-bottom: 45px;
        }
    }

    &__meta {
        .-text-over & {
            @include spacing(38, 67, 88, 97);
        }

        &-item {
            @extend %bodyText;
            display: inline-block;

            &.-no-inline {
                display: block;
            }

            &.-translation {
                &:nth-child(2) {
                    margin-left: 10px;
                }
            }

            &.-publish-date {
                &:nth-child(2),
                &:nth-child(3) {
                    margin-left: 10px;
                }
            }

            &.-social {
                display: block;

                a {
                    color: $black;
                    @extend %textLink;
                }
            }
        }

        &-label {
            font-family: 'Stanley';
            font-weight: bold;
            -webkit-font-smoothing: antialiased;
        }
    }

    &__block {
        &.-grid {
            @extend %gridWrap;
        }

        &.-intro {
            @include spacing(33, 62, 82, 92);
            @extend %headingM;
            text-align: center;

            span {
                display: block;
                @extend %center;
                @include mqmed {
                    max-width: 390px;
                }
                @include mqbig {
                    max-width: 490px;
                }
                @include mqhuge {
                    max-width: 560px;
                }
            }
        }

        &.-contained {
            @extend %bodyGutter;
        }

        &.-quote {
            text-align: center;
            @include spacing(50, 80, 100, 110);

            blockquote {
                margin: 0;
            }

            p {
                font-size: 25px;
                line-height: (30/25);
                @extend %center;
                @extend %heading;
                max-width: 430px;
                @include mqmed {
                    font-size: 33px;
                    line-height: (40/33);
                    max-width: 630px;
                }
                @include mqbig {
                    font-size: 45px;
                    line-height: (50/45);
                    max-width: 730px;
                }
                @include mqhuge {
                    font-size: 60px;
                    line-height: (70/60);
                    max-width: 830px;
                }
            }

            span {
                @extend %bodyText;
                line-height: 1;
                display: block;
                margin-top: 15px;
                @include mqmed {
                    margin-top: 25px;
                }
                @include mqbig {
                    margin-top: 45px;
                }
                @include mqhuge {
                    margin-top: 50px;
                }
            }
        }

        &.-audio {
            // @extend %center;
            @include spacing(38, 68, 88, 98);
            margin-bottom: 2em !important;
            padding: 0 $bodyGutterSmall;

            @include mqmed {
                padding: 0 $bodyGutterMed;
            }

            @include mqbig {
                padding: 0 ($bodyGutterBig - ($gridGutterBig/2));
            }

            @include mqhuge {
                padding: 0 ($bodyGutterHuge - ($gridGutterHuge/2));
            }

            &[data-pb] {
                margin-bottom: 6em !important;
            }
        }

        &.-text {
            // @extend %center;
            @include spacing(38, 68, 88, 98);
            padding: 0 $bodyGutterSmall;
            @include mqmed {
                padding: 0 $bodyGutterMed;
            }
            @include mqbig {
                padding: 0 ($bodyGutterBig - ($gridGutterBig/2));
            }
            @include mqhuge {
                padding: 0 ($bodyGutterHuge - ($gridGutterHuge/2));
            }

            a {
                @extend %textLink;
            }

            blockquote {
                @extend %articleTextMargin;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;

                &:before {
                    content: "« ";
                }

                &:after {
                    content: "»";
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            h2 {
                @extend %headingS;
                text-align: center;
            }

            ul {
                list-style: disc;
                margin-left: 3em;
                margin-right: 3em;
            }

            ol {
                margin-left: 3em;
                margin-right: 3em;
                list-style: decimal;
            }

            li {
                display: list-item;
                margin-bottom: 0.25em;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            p, ol, ul {
                @extend %articleTextMargin;

                strong {
                    font-weight: 500;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                & + h2 {
                    @include spacing(23, 63, 83, 92, 0, 'margin-top');
                }
            }
        }

        &.-image {
            @include spacing(38, 67, 88, 97);

            img {
                @include spacing(13, 18, 18, 23);
            }

            picture:last-child {
                img {
                    margin-bottom: 0;
                }
            }
        }

        &.-image-single {
            &:not(.-contained) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.-image-double {
            @extend %clearfix;
        }

        &.-image-triple {
            @extend %clearfix;
            padding: 0 $bodyGutterSmall;
            @include mqmed {
                padding: 0 ($bodyGutterMed - ($gridGutterMed/2));
            }
            @include mqbig {
                padding: 0 ($bodyGutterBig - ($gridGutterBig/2));
            }
            @include mqhuge {
                padding: 0 ($bodyGutterHuge - ($gridGutterHuge/2));
            }
        }

    }
}

.article-image {
    .-image-triple & {
        width: 100%;
        @include mqmed {
            width: 33.333%;
            float: left;
            padding: 0 ($gridGutterMed/2);
        }
        @include mqbig {
            padding: 0 ($gridGutterBig/2);
        }
        @include mqhuge {
            padding: 0 ($gridGutterHuge/2);
        }
    }

    .-image-double & {
        width: 100%;
        @include mqmed {
            width: 50%;
            float: left;
        }

        &:first-child {
            @include mqmed {
                padding-right: $gridGutterMed/2;
            }
            @include mqbig {
                padding-right: $gridGutterBig/2;
            }
            @include mqhuge {
                padding-right: $gridGutterHuge/2;
            }
        }

        &:nth-child(2) {
            @include mqmed {
                padding-left: $gridGutterMed/2;
            }
            @include mqbig {
                padding-left: $gridGutterBig/2;
            }
            @include mqhuge {
                padding-left: $gridGutterHuge/2;
            }
        }
    }

    &__sub-text {
        text-align: center;

        .-image-single & {
            @extend %bodyGutter;
            @include spacing(38, 0, 0, 0);
        }

        .-image-double & {
            @include spacing(25, 0, 0, 0);
        }

        .-image-triple & {
            @include spacing(25, 0, 0, 0);
        }

        .article-image:last-child & {
            margin-bottom: 0;
        }

    }

    &__caption {
        @extend %bodyText;
    }

    &__credit {

    }
}

.article__col {
    &.-text {
        @extend %center;
        @include mqmed {
            padding: 0 $gridGutterMed;
            max-width: 780px;
        }
        @include mqbig {
            max-width: 114ch;
            width: 66.6667%;
            padding: 0 $gridGutterBig/2;
        }
    }
}

.related {
    background: $grey2;
    @include spacing(50, 80, 100, 110, 6, 'padding-top');

    h2 {
        @include spacing(50, 80, 100, 110, 6);
        @extend %headingM;
        text-align: center;
    }
}

@keyframes spin {
    from {
        transform: rotateZ(0);
    }

    to {
        transform: rotateZ(1turn);
    }
}

.green-audio-player {
    width: 400px;
    min-width: 300px;
    height: 56px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 4px;
    user-select: none;
    background-color: #fff;
    box-sizing: border-box;
    outline: none;
}

.green-audio-player.player-accessible .play-pause-btn:hover,
.green-audio-player.player-accessible .controls:hover,
.green-audio-player.player-accessible .volume__button:hover,
.green-audio-player.player-accessible .volume__controls:hover,
.green-audio-player.player-accessible .download:hover {
    outline: dotted 1px #999;
}

.green-audio-player svg,
.green-audio-player img {
    display: block;
}

.green-audio-player .holder {
    position: relative;
}

.green-audio-player .holder .loading .loading__spinner {
    position: absolute;
    left: -3px;
    bottom: 2px;
    width: 16px;
    height: 16px;
    border: 2px solid #b0b0b0;
    border-right-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    animation: spin 0.4s linear infinite;
}

.green-audio-player .holder .play-pause-btn {
    visibility: hidden;
    cursor: pointer;
    outline: none;
}

.green-audio-player .holder .play-pause-btn:focus {
    outline: none;
}

.green-audio-player .slider {
    flex-grow: 1;
    background-color: #d8d8d8;
    cursor: pointer;
    position: relative;
}

.green-audio-player .slider .gap-progress {
    background-color: #44bfa3;
    border-radius: inherit;
    position: absolute;
    pointer-events: none;
}

.green-audio-player .slider .gap-progress .pin {
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background-color: #44bfa3;
    position: absolute;
    pointer-events: all;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.32);
}

.green-audio-player .slider .gap-progress .pin::after {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0);
    width: 200%;
    height: 200%;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 50%;
}

.green-audio-player .controls {
    /* font-family: "Roboto", sans-serif; */
    font-size: 16px;
    line-height: 18px;
    color: #55606e;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    outline: none;
}

.green-audio-player .controls .controls__slider {
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 2px;
    height: 4px;
}

.green-audio-player .controls .controls__slider .controls__progress {
    width: 0;
    height: 100%;
}

.green-audio-player .controls .controls__slider .controls__progress .progress__pin {
    right: -8px;
    top: -6px;
}

.green-audio-player .controls span {
    cursor: default;
}

.green-audio-player .controls:focus {
    outline: none;
}

.green-audio-player .volume {
    position: relative;
}

.green-audio-player .volume .volume__button {
    cursor: pointer;
    outline: none;
}

.green-audio-player .volume .volume__button:focus {
    outline: none;
}

.green-audio-player .volume .volume__button.open path {
    fill: #44bfa3;
}

.green-audio-player .volume .volume__controls {
    width: 30px;
    height: 135px;
    background-color: rgba(0, 0, 0, 0.62);
    border-radius: 7px;
    position: absolute;
    left: -3px;
    bottom: 52px;
    flex-direction: column;
    align-items: center;
    display: flex;
    z-index: 2;
    outline: none;
}

.green-audio-player .volume .volume__controls .volume__slider {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 6px;
    border-radius: 3px;
}

.green-audio-player .volume .volume__controls .volume__slider .volume__progress {
    bottom: 0;
    height: 100%;
    width: 6px;
}

.green-audio-player .volume .volume__controls .volume__slider .volume__progress .volume__pin {
    left: -5px;
    top: -8px;
}

.green-audio-player .volume .volume__controls:focus {
    outline: none;
}

.green-audio-player .volume .volume__controls.hidden {
    display: none;
}

.green-audio-player .volume .volume__controls.top {
    bottom: 52px;
    left: -3px;
}

.green-audio-player .volume .volume__controls.middle {
    bottom: -54px;
    left: 54px;
}

.green-audio-player .volume .volume__controls.bottom {
    bottom: -164px;
    left: -3px;
}

.green-audio-player .download {
    display: none;
    margin-left: 16px;
    cursor: pointer;
    outline: none;
}

.green-audio-player .download:focus {
    outline: none;
}

.green-audio-player:focus {
    outline: none;
}

.message__offscreen {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}


.green-audio-player {
    box-shadow: none;
    box-shadow: none;
    background-color: #f7f7f7;
    margin-top: 1em;

    .controls {
        color: #000;
    }

    .slider {
        .gap-progress {
            background-color: #777;

            .pin {
                background-color: #000;
                box-shadow: none;
            }
        }
    }


    svg path {
        fill: #000;
    }
}