// Color
$colorText: #231f20;
$color1: #231F20;

$black: #231f20;
$grey: #605b5d;
$grey2: #eaeaea;
$peach: #f2caaa;
$peach2: #f9ddca;

// Media sizes

$mqsmall: 400px;
$mqsmallish: 600px;
$mqmed: 760px; // Ipad portrait
$mqmedish: 960px;
$mqbig: 1200px; // Desktop
$mqbigish: 1400px;
$mqhuge: 1600px; // Desktop+
$mqmax: 1900px;

/* Padding */
/*----------------------------------------------*/

// Body gutter padding

$bodyGutterSmall: 25px;
$bodyGutterMed: 50px;
$bodyGutterBig: 95px;
$bodyGutterHuge: 100px;

// Vertical margins

$marginSmall: 30px;
$marginSmall2: 40px;

$marginMed: 75px;
$marginMed2: 75px;

$marginBig2: 100px;

// Grid gutter padding

$gridGutterSmall: 20px;
$gridGutterMed: 34px;
$gridGutterBig: 40px;
$gridGutterHuge: 44px;
