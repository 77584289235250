.hero {
    &.-home {
        @include spacing(25, 50, 80, 90);
        position: relative;
        color: #fff;
        overflow: hidden;
    }

    &__overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        &.-default {
            background: #985E2F;
            opacity: 0.3;
        }
    }

    &__slides {
        position: relative;

    }

    &__slide {
        position: relative;
        z-index: 1;

        img {
            width: 100%;
            height: auto;
        }

        &:not(:first-child) {
            z-index: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &__slide-nav {
        display: none;
        @include mqmed {
            display: block;
            position: absolute;
            z-index: 3;
            top: 50%;
            transform: translateY(-50%);
            font-size: 38px;
            padding: 10px;
            cursor: pointer;
            transition: transform 0.25s;
            svg {
                width: 1em;
                height: 0.5em;
                display: block;
            }
            path {
                fill: #fff;
            }
            &.-prev {
                left: $bodyGutterSmall - 10px;
                @include mqmed {
                    left: $bodyGutterMed - 10px;
                }
                @include mqbig {
                    left: $bodyGutterBig - 10px;
                }
                @include mqhuge {
                    left: $bodyGutterHuge - 10px;
                }

                &:hover {
                    transform: translateY(-50%) translateX(-10px);
                }
            }
            &.-next {
                right: $bodyGutterSmall - 10px;
                @include mqmed {
                    right: $bodyGutterMed - 10px;
                }
                @include mqbig {
                    right: $bodyGutterBig - 10px;
                }
                @include mqhuge {
                    right: $bodyGutterHuge - 10px;
                }

                &:hover {
                    transform: translateY(-50%) translateX(10px);
                }
            }
        }
    }

    &__link {
        color: #fff;
        transition: none;

        &:hover {
            color: #fff;
        }
    }

    &__text {
        &.-wrap {
            -webkit-font-smoothing: antialiased;
            position: absolute;
            z-index: 2;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            max-width: 450px;
            width: 100%;
            padding: 0 $bodyGutterSmall;
            @include mqmedish {
                padding: 0;
                max-width: 600px;
            }
            @include mqbig {
                max-width: 750px;
            }
            @include mqhuge {
                max-width: 950px;
            }
        }

        &.-blurb {
            @extend %heading;
            display: block;
            font-size: 25px;
            line-height: 1.2;
            margin-bottom: 20px;
            @include mqmed {
                font-size: 33px;
                margin-bottom: 30px;
            }
            @include mqbig {
                font-size: 45px;
                margin-bottom: 40px;
            }
            @include mqhuge {
                font-size: 60px;
                margin-bottom: 55px;
            }
        }

        &.-link {
            @extend %bodyText;
            display: inline-block;
            box-shadow: inset 0 -1px 0 transparentize(#fff, 0);
            transition: box-shadow 0.35s;

            .hero__link:hover & {
                box-shadow: inset 0 -1px 0 transparentize(#fff, 1);
            }
        }

    }
}

.block {
    position: relative;

    &.-bottom-margin {
    }

    &.-contain {
        @extend %bodyGutter;
    }

    &__heading {
        &-wrap {
            pointer-events: none;
            display: none;
            @include mqmedish {
                display: block;
                position: absolute;
                height: 100%;
                width: $bodyGutterMed;
                left: -$bodyGutterMed;
                transform: translateZ(0);
            }
            @include mqbig {
                width: $bodyGutterBig;
                left: -$bodyGutterBig;
            }
            @include mqhuge {
                width: $bodyGutterHuge;
                left: -$bodyGutterHuge;
            }
        }

        @extend %heading;
        @include mqmedishmax {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        @include mqmedish {
            text-align: center;
            line-height: 1;
            font-size: 18px;
        }
        @include mqbig {
            font-size: 23px;
        }
        @include mqhuge {
            font-size: 29px;
        }

        .csstransforms .-light & {
            color: #fff;
            -webkit-font-smoothing: antialiased;
        }

        .csstransforms & {
            pointer-events: none;
            position: absolute;
            left: $bodyGutterMed/2;
            top: 50%;
            transform: rotate(-90deg) translateY(-50%) translateZ(0);
            transform-origin: center center;
            z-index: 99;
            width: 1px;
            height: 1px;
            @include mqbig {
                left: $bodyGutterBig/2;
            }
            @include mqhuge {
                left: $bodyGutterHuge/2;
            }

            span {
                display: block;
                position: absolute;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }

    &--big-image-link {
        @include mqmedish {
            padding: 0 $bodyGutterMed;
        }
        @include mqbig {
            padding: 0 $bodyGutterBig;
        }
        @include mqhuge {
            padding: 0 $bodyGutterHuge;
        }
    }

    &__feature {
        @include spacing(25, 50, 80, 90);
        position: relative;

        a {
            position: relative;
            display: block;
            overflow: hidden;
            color: $black;
        }

        img {
            display: block;
        }

        path {
            fill: $black;
        }

        &.-light {
            path {
                fill: #fff;
            }

            a {
                color: #fff;
            }
        }

        &-text-wrap {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            text-align: center;
            width: 100%;
            @extend %bodyGutter;
        }

        &-overlay {
            z-index: 1;
            position: absolute;
            width: 102%;
            height: 102%;
            top: -1%;
            left: -1%;
            background: transparentize(#fff, 1);
            transition: background 0.35s $easeOutSine;

            .not-using-touch a:hover & {
                background: transparentize(#fff, 0.7);
            }

            &.-dark {
                background: transparentize($black, 0.3);

                .not-using-touch a:hover & {
                    background: transparentize($black, 0.25);
                }
            }
        }
    }

    &__text {
        &--heading {
            @extend %headingXLalt;
            @include mqbig {
                max-width: 1000px;
                margin: 0 auto;
            }

            &.-constrain {
                @include mqbig {
                    max-width: 500px;
                }
                @include mqhuge {
                    max-width: 600px;
                }
            }
        }

        &--logo {
            display: block;
            width: 65%;
            height: auto;
            max-width: 900px;
            margin: 20px auto;
            @include mqmed {
                width: 52%;
            }
        }

        &--main {
            @extend %heading;
            font-size: 18px;
            line-height: (23/18);
            @include mqmed {
                font-size: 26px;
                line-height: (32/26);
            }
            @include mqbig {
                font-size: 30px;
                line-height: (36/30);
            }
            @include mqbigish {
                font-size: 32px;
                line-height: (38/32);
            }
            @include mqhuge {
                font-size: 37px;
                line-height: (43/37);
            }
        }

        &--top {
            @extend %bodyText;
            @include spacing(25, 25, 36, 50);
            display: inline-block;
        }

        &--read {
            @extend %bodyText;
            @include spacing(25, 25, 36, 50, 0, 'margin-top');
            display: inline-block;
            box-shadow: inset 0 -1px 0 $black;
            padding-bottom: 5px;
            transition: box-shadow 0.35s $easeOutSine;

            .-light & {
                box-shadow: inset 0 -1px 0 #fff;
            }

            .block--single & {
                box-shadow: inset 0 -1px 0 $black;
            }

            .not-using-touch a:hover & {
                box-shadow: inset 0 -1px 0 transparentize($black, 1);
            }

            .not-using-touch .-light a:hover & {
                box-shadow: inset 0 -1px 0 transparentize(#fff, 1);
            }

            .not-using-touch .block--single a:hover & {
                box-shadow: inset 0 -1px 0 transparentize($black, 1);
            }
        }
    }

    &__arrow {
        margin-left: 5px;
        transition: transform 0.35s $easeOutSine;
        display: inline-block;

        .not-using-touch a:hover & {
            transform: translateX(5px);
        }

        @include mqbig {
            font-size: 16px;
        }

        svg {
            width: 1em;
            height: 0.5em;
        }
    }

    &--single {
        @include spacing(25, 50, 80, 90);
        position: relative;


        a {
            display: block;
            @extend %clearfix;
            @include mqmed {
                .flexbox & {
                    display: flex;
                }
            }
        }

        &__image {
            width: 100%;
            position: relative;
            overflow: hidden;

            img {
                display: block;
            }

            @include mqmed {
                z-index: 2;
                width: 50%;
                float: left;
                .flexbox & {
                    flex: 1;
                }
            }

            &-overlay {
                z-index: 1;
                background: #fff;
                display: block;
                opacity: 0;
                position: absolute;
                left: -1%;
                top: -1%;
                width: 102%;
                height: 102%;
                transition: opacity 0.35s $easeOutSine;

                .not-using-touch a:hover & {
                    opacity: 0.3;
                }
            }
        }

        &__text {
            background: #f3f3f3;
            @include mqmed {
                position: absolute;
                height: 100%;
                right: $bodyGutterMed;
                padding-left: $bodyGutterMed;
                overflow: hidden;
                width: 50%;
                float: left;
                z-index: 1;
            }
            @include mqbig {
                right: $bodyGutterBig;
                padding-left: $bodyGutterBig;
            }

            .flexbox & {
                @include mqmed {
                    position: relative;
                    padding-left: 0;
                    right: auto;
                    height: auto;
                    flex: 1;
                }
            }

            &-wrap {
                padding: 25px 25px;
                text-align: center;
                @include mqmed {
                    max-height: 95%;
                    position: absolute;
                    padding: 0 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    .flexbox & {
                        width: 100%;
                        overflow: hidden;
                    }
                }
                @include mqbig {
                    padding: 0 50px;
                }
            }
        }
    }
}
