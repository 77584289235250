.cookie-banner {
    position: fixed;
    display: none;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: $black;
    color: #fff; 
     -webkit-font-smoothing: antialiased;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    
    &__inner {
        padding: 20px;
        text-align: center;
        
        @include mqbig {
            padding: 30px;
        }
        
        p, button {
            display: inline-block;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        
        button {
            background: $black;
            color: #fff;
            padding: 5px 20px;
            text-transform: uppercase;
            margin-left: 30px;
            margin-right: 30px;
            transition-property: background, color;
            transition-duration: 0.1s;
            transition-timing-function: ease;
            
            &:hover {
                background: #fff;
                color: $black;
            }
        }
    }
}
