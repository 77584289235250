/* Mixins */

// Media queries

@mixin mqsmall {
  @media (min-width: #{$mqsmall}) {
    @content;
  }
}

@mixin mqsmallmax {
  @media (max-width: #{$mqmed - 1px}) {
    @content;
  }
}

@mixin mqsmallish {
  @media (min-width: #{$mqsmallish}) {
    @content;
  }
}

@mixin mqmed {
  @media (min-width: #{$mqmed}) {
    @content;
  }
}

@mixin mqmedish {
  @media (min-width: #{$mqmedish}) {
    @content;
  }
}

@mixin mqmedmax {
  @media (max-width: #{$mqbig - 1px}) {
    @content;
  }
}

@mixin mqmedishmax {
  @media (max-width: #{$mqmedish - 1px}) {
    @content;
  }
}

@mixin mqbig {
  @media (min-width: #{$mqbig}) {
    @content;
  }
}

@mixin mqbigish {
  @media (min-width: #{$mqbigish}) {
    @content;
  }
}

@mixin mqbigmax {
  @media (min-width: #{$mqbig}) and (max-width: #{$mqhuge - 1px}) {
    @content;
  }
}

@mixin mqhuge {
  @media (min-width: #{$mqhuge}) {
    @content;
  }
}

@mixin mqmax {
  @media (min-width: #{$mqmax}) {
    @content;
  }
}

@mixin mqportrait {
  @media  (orientation: portrait) {
    @content;
  }
}

// Helpers
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Javascript breakpoints

@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}
body {
  &:after {
    content: " ";
    display: none;
    position: relative;
    z-index: strip-units($mqsmall);
    @include mqsmallish {
      z-index: strip-units($mqsmallish);
    }
    @include mqmed {
      z-index: strip-units($mqmed);
    }
    @include mqmedish {
      z-index: strip-units($mqmedish);
    }
    @include mqbig {
      z-index: strip-units($mqbig);
    }
    @include mqhuge {
      z-index: strip-units($mqhuge);
    }
  }
}

// Spacing

@mixin spacing($mobile, $ipad, $desktop, $desktopPlus, $modifier: 0, $type: "margin-bottom", $additional: false) {
  /* SPACING! */
  #{$type}: ($mobile - $modifier)+px;
  @if $additional {
    #{$additional}: ($mobile - $modifier)+px;
  }
  @if $ipad != $mobile {
    @include mqmed {
      #{$type}: ($ipad - $modifier)+px;
      @if $additional {
        #{$additional}: ($ipad - $modifier)+px;
      }
    }
  }
  @if $desktop != $ipad {
    @include mqbig {
      #{$type}: ($desktop - $modifier)+px;
      @if $additional {
        #{$additional}: ($desktop - $modifier)+px;
      }
    }
  }
  @if $desktopPlus != $desktop {
    @include mqhuge {
      #{$type}: ($desktopPlus - $modifier)+px;
      @if $additional {
        #{$additional}: ($desktopPlus - $modifier)+px;
      }
    }
  }
}
