.search {
  &__close {
    &--results-top {
      position: absolute;
      top: 40px;
      right: $bodyGutterSmall;
      display: block;
      width: 30px;
      height: 30px;
      // background: #f2f2f2;
      transition: transform 0.75s cubic-bezier(0.860, 0.000, 0.070, 1.000);
      @include mqmed {
        right: $bodyGutterMed;
      }
      @include mqbig {
        right: $bodyGutterBig;
      }
      @include mqhuge {
        right: $bodyGutterHuge;
      }
      &:hover {
        transform: rotateZ(360deg);
      }
    }
    &.-x {
      width: 2px;
      height: 20px;
      background: #000;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      // transition: transform 1s cubic-bezier(0.860, 0.000, 0.070, 1.000);
    }
    &.-x1 {
      transform: translateX(-50%) translateY(-50%) rotateZ(45deg) ;
    }
    &.-x2 {
      transform:  translateX(-50%) translateY(-50%) rotateZ(-45deg);
    }
  }
  &__close--results {
    display: inline-block;
    border: 1px solid $black;
    padding: 10px;
    transition: background 0.25s ease-out, color 0.25s ease-out;
    &:hover {
      background: #000;
      color: #fff;
    }
  }
  &__result {
    @extend %clearfix;
    @extend %bodyGutter;
    display: none;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #fff;
    overflow: hidden;
    overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    @include mqmed {

    }
    @include mqbig {

    }
    @include mqhuge {

    }
    .search-standalone & {
      display: block;
      opacity: 1;
      position: relative;
      overflow: auto;
    }
    &-wrapper {
      @include mqmed {
        max-width: 575px;
        margin: 0 auto;
      }
      @include mqbig {
        max-width: 817px;
      }
      @include mqhuge {
        max-width: 877px;
      }
    }
    &__info {
      @extend %headingXLalt;
      @include spacing(30,75,86,100)
    }

    li {
      @include spacing(35,50,50,90);
      a {
        border: 1px solid $black;
        display: block;
        padding: 40px;
        color: $black;
        transition: background 0.15s ease-out, color 0.15s ease-out, transform 0.15s ease-out;
        @include mqbig {
          padding: 50px;
        }
        &:hover {
          transform: scale(1.03);
          background: $black;
          color: #fff;
          path {
            fill: #fff;
          }
        }
      }
    }

    &__type {
      font-size: 15px;
      line-height: 1;
      @include mqbig {
        font-size: 18px;
      }
      @include mqhuge {
        font-size: 21px;
      }
    }

    &__title {
      display: block;
      @extend %heading;
      font-size: 18px;
      margin: 20px 0;
      @include mqmed {
        font-size: 26px;
        margin: 30px 0;
      }
      @include mqbig {
        font-size: 32px;
        margin: 50px 0;
      }
      @include mqhuge {
        font-size: 35px;
      }
    }

    &__arrow {
      display: inline-block;
      .svg-wrapper {
        display: inline-block;
        width: 18px;
        font-size: 18px;
        line-height: 0.52;
        @include mqmed {
          width: 22px;
          font-size: 22px;
        }
        @include mqbig {
          width: 25px;
          font-size: 25px;
        }
      }
      svg {
        width: 1em;
        height: 0.52em;
      }
      path {
        transition: fill 0.25s ease-out;
      }
        &-text {
            display: inline-block;
            margin-right: 10px;
            position: relative;
            top: -2px;
        }
    }
  }
  &__form {
    .search-standalone & {
      @extend %bodyGutter;
      .search__input {
        border-bottom: 1px solid #000;
      }
    }
  }
}
