.archive {
    &__title {
        @extend %headingXLalt;
        @include spacing(20, 45, 64, 80);
        text-align: center;
    }

    &__category-form {
        @include mqsmallish {
            display: none;
        }
    }

    &__category-wrap {
        @extend %bodyGutter;
        //@include spacing(30, 80, 80, 90);
        @include spacing(20, 45, 64, 80);
    }

    &__newsletter {
        @include spacing(20, 45, 64, 80);
        text-align: center;
        margin-top: -20px;
        a {
            @extend %bodyText;
            padding-bottom: 4px;
            box-shadow: inset 0 -1px 0 #231f20;
            transition: box-shadow 0.35s $easeOutSine;
            &:hover {
                box-shadow: inset 0 -1px 0 transparentize(#231f20,1);
                span {
                    transform: translateX(3px) translateY(-3px);
                }
            }
        }
        span {
            display: inline-block;
            padding-left: 5px;
            position: relative;
            top: -1px;
            transition: transform 0.35s ease-out;
        }
        svg {
            width: 0.666em;
            height: 0.666em;
            path {
                fill: currentColor;
            }
        }
    }

    &__category-list {
        &.-wrap {
            display: none;

            @include mqsmallish {
                display: block;
                text-align: center;
            }
        }

        &.-item {
            display: inline-block;
            margin: 0 10px 10px;
            perspective: 100px;

            a {
                font-size: 12px;
                display: block;
                line-height: 1;
                padding: 12px 10px 13px;
                border: 1px solid $black;
                transition: background 0.35s, color 0.35s;
                -webkit-font-smoothing: antialiased;
                @include mqmedish {
                    font-size: 15px;
                }
                @include mqhuge {
                    font-size: 18px;
                }

                &.-isSelected, &:hover {
                    background: $black;
                    color: #fff;

                }
            }
        }
    }
}

.SumoSelect p {
    margin: 0;
}

.SlectBox {
    width: 100%;
    padding: 5px 8px;
}

/*this is applied on that hidden select. DO NOT USE display:none; or visiblity:hidden; and Do not override any of these properties. */
.SelectClass {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    border: none;
    z-index: 1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0;
}

.SumoSelect > .optWrapper > .options > li label, .SumoSelect > .CaptionCont {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.SumoSelect {
    display: inline-block;
    position: relative;
    outline: none;
    width: 100%;
}

.SumoSelect:focus > .CaptionCont, .SumoSelect:hover > .CaptionCont {
    box-shadow: 0px 0px 2px #7799D0;
    border-color: #7799D0;
}

.SumoSelect > .CaptionCont {
    position: relative;
    border: 1px solid $black;
    min-height: 14px;
    background-color: #fff;
    border-radius: 0;
    margin: 0px;
}

.SumoSelect > .CaptionCont > span {
    display: block;
    padding-right: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
}

/*placeholder style*/
.SumoSelect > .CaptionCont > span.placeholder {
    color: #ccc;
    font-style: italic;
}

.SumoSelect > .CaptionCont > label {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 30px;
}

.SumoSelect > .CaptionCont > label > i {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 6px 0 6px;
    border-color: $black transparent transparent transparent;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    background-repeat: no-repeat;
    opacity: 0.8;
}

.SumoSelect > .optWrapper {
    top: 30px;
    width: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-out, top 200ms ease-out, visibility 200ms ease-out;
    -webkit-transition: opacity 200ms ease-out, top 200ms ease-out, visibility 200ms ease-out;
    -moz-transition: opacity 200ms ease-out, top 200ms ease-out, visibility 200ms ease-out;
    -ms-transition: opacity 200ms ease-out, top 200ms ease-out, visibility 200ms ease-out;
    -o-transition: opacity 200ms ease-out, top 200ms ease-out, visibility 200ms ease-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    z-index: -100;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.11);
    border-radius: 3px;
    overflow: hidden;
}

.SumoSelect > .optWrapper.open {
    top: 35px;
    visibility: visible;
    opacity: 1;
    z-index: 1000;
}

.SumoSelect > .optWrapper > .options {
    list-style: none;
    display: block;
    padding: 0px;
    margin: 0px;
    overflow: auto;
    border-radius: 2px;
    /*Set the height of pop up here (only for desktop mode)*/
    max-height: 250px;
    /*height*/
}

.SumoSelect > .optWrapper.isFloating > .options {
    max-height: 100%;
    box-shadow: 0px 0px 100px #595959;
}

.SumoSelect > .optWrapper > .options > li {
    padding: 6px 6px;
    border-bottom: 1px solid #F3F3F3;
    position: relative;
}

.SumoSelect > .optWrapper > .options > li:first-child {
    border-radius: 2px 2px 0px 0px;
}

.SumoSelect > .optWrapper > .options > li:last-child {
    border-bottom: none;
    border-radius: 0px 0px 2px 2px;
}

.SumoSelect > .optWrapper > .options > li:hover {
    background-color: #E4E4E4;
}

.SumoSelect > .optWrapper > .options > li.sel {
    background-color: #a1c0e4;
}

.SumoSelect > .optWrapper > .options > li label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    cursor: pointer;
}

.SumoSelect > .optWrapper > .options > li span {
    display: none;
}

/*Floating styles*/
.SumoSelect > .optWrapper.isFloating {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 90%;
    bottom: 0px;
    margin: auto;
    max-height: 90%;
}

/*Hover*/
/*.SumoSelect:hover > .CaptionCont > label { background-color: #F1F1F1; }*/

/*disabled state*/
.SumoSelect > .optWrapper > .options > li.disabled {
    background-color: inherit;
    pointer-events: none;
}

.SumoSelect > .optWrapper > .options > li.disabled * {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)"; /* IE 5-7 */
    filter: alpha(opacity=50); /* Netscape */
    -moz-opacity: 0.5; /* Safari 1.x */
    -khtml-opacity: 0.5; /* Good browsers */
    opacity: 0.5;
}


/*styling for multiple select*/
.SumoSelect > .optWrapper.multiple > .options > li {
    padding-left: 35px;
    cursor: pointer;
}

.SumoSelect > .optWrapper.multiple > .options > li span,
.SumoSelect .select-all > span {
    position: absolute;
    display: block;
    width: 30px;
    top: 0px;
    bottom: 0px;
    margin-left: -35px;
}

.SumoSelect > .optWrapper.multiple > .options > li span i,
.SumoSelect .select-all > span i {
    position: absolute;
    margin: auto;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 14px;
    height: 14px;
    border: 1px solid #AEAEAE;
    border-radius: 2px;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.15);
    background-color: #fff;
}

.SumoSelect > .optWrapper > .MultiControls {
    display: none;
    border-top: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.13);
    border-radius: 0px 0px 3px 3px;
}

.SumoSelect > .optWrapper.multiple.isFloating > .MultiControls {
    display: block;
    margin-top: 5px;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls {
    display: block;
}

.SumoSelect > .optWrapper.multiple.okCancelInMulti > .MultiControls > p {
    padding: 6px;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p {
    display: inline-block;
    cursor: pointer;
    padding: 12px;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p:hover {
    background-color: #f1f1f1;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnOk {
    border-right: 1px solid #DBDBDB;
    border-radius: 0px 0px 0px 3px;
}

.SumoSelect > .optWrapper.multiple > .MultiControls > p.btnCancel {
    border-radius: 0px 0px 3px 0px;
}

/*styling for select on popup mode*/
.SumoSelect > .optWrapper.isFloating > .options > li {
    padding: 12px 6px;
}

/*styling for only multiple select on popup mode*/
.SumoSelect > .optWrapper.multiple.isFloating > .options > li {
    padding-left: 35px;
}

.SumoSelect > .optWrapper.multiple.isFloating {
    padding-bottom: 43px;
}

/*selected state
.SumoSelect > .optWrapper.multiple > .options > li.selected span i:after,
.SumoSelect .select-all.selected > span i:after{content: '';position: absolute;width: 11px;height: 11px;top: 2px;left: 2px;background-color: rgb(17, 169, 17);border-radius: 2px;box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);}
*/
.SumoSelect > .optWrapper.multiple > .options > li.selected span i,
.SumoSelect .select-all.selected > span i,
.SumoSelect .select-all.partial > span i {
    background-color: rgb(17, 169, 17);
    box-shadow: none;
    border-color: transparent;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: center center;
}

/*disabled state*/
.SumoSelect.disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.SumoSelect.disabled > .CaptionCont {
    border-color: #ccc;
    box-shadow: none;
}

/**Select all button**/
.SumoSelect .select-all {
    border-radius: 3px 3px 0px 0px;
    position: relative;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    padding: 8px 0px 3px 35px;
    height: 20px;
}

.SumoSelect .select-all > span i {
    cursor: pointer;
}

.SumoSelect .select-all.partial > span i {
    background-color: #ccc;
}


/*styling for optgroups*/
.SumoSelect > .optWrapper > .options > li.optGroup {
    padding-left: 5px;
    text-decoration: underline;
}
