body {

    padding-top: 73px;
    @include mqmed {
      padding-top: 106px;
    }
    @include mqbig {
      padding-top: 124px;
    }
    @include mqhuge {
      padding-top: 151px;
    }
    &.homepage {
      padding-top: 0;
    }

}

.page-header {
  @extend %bodyGutter;
  // background: #fff;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  @include mqmed {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @include mqbig {
    padding-top: 36px;
    padding-bottom: 36px;
    // padding: 15px 95px;
  }
  @include mqhuge {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  &.-transparent {
    background-color: transparent;
  }
  &__wrapper {
    position: relative;
    @extend %clearfix;
  }
  &.-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  &__spacer {
    display: block;
    height: 10px;
    @include mqbig {
      display: none;
    }
  }
  &.-small {
    padding-top: 7px;
    padding-bottom: 7px;
    @include mqbig {
      // animation: headerShrink 2s;
      padding-top:15px;padding-bottom:15px;
    }
  }
  &.-grow {
    // transform: translateZ(0);
    backface-visibility: hidden;
    animation: headerGrow 0.35s;
    @include mqmed {
      animation: headerGrowMed 0.35s;
    }
    @include mqbig {
      animation: headerGrowBig 0.35s;
    }
    @include mqhuge {
      animation: headerGrowHuge 0.35s;
    }
  }
  &:not(.-small) {
    .homepage & {
      path {
        fill: #fff;
      }
      .open-search {
        color: #fff;
      }
      .open-menu {
        color: #fff;
        border-color: #fff;
        &:hover {
          background: #fff;
          color: $black;
        }
      }
      .menu a {
        @include mqmedish {
          color: #fff;
          box-shadow: inset 0 -1px 0 transparentize(#fff, 1);
          &:hover {
            box-shadow: inset 0 -1px 0 transparentize(#fff, 0);
          }
        }
      }
    }
  }
}

@keyframes headerGrow {
  from {padding-top:7px;padding-bottom:7px;}
  to {padding-top:20px;padding-bottom:20px;}
}

@keyframes headerGrowMed {
  from {padding-top:7px;padding-bottom:7px;}
  to {padding-top:30px;padding-bottom:30px;}
}

@keyframes headerGrowBig {
  from {padding-top:15px;padding-bottom:15px;}
  to {padding-top:36px;padding-bottom:36px;}
}

@keyframes headerGrowHuge {
  from {padding-top:15px;padding-bottom:15px;}
  to {padding-top:45px;padding-bottom:45px;}
}

.logo {
  line-height: 0;
  display: inline-block;
  font-size: 101px;
  float: left;
  width: 1em;
  height: 0.3285em;
  position: relative;
  z-index: 10;
  overflow: hidden;
  @include mqmed {
    font-size: 140px;
  }
  @include mqbig {
    font-size: 157px;
  }
  @include mqhuge {
    font-size: 186px;
  }

  svg {
    width: 1em;
    height: 0.3285em;
    display: block;
  }
  &__big {
    display: block;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
  }
  &__small {
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateX(180deg);
  }
}

.the-vessel {
  display: none;
  position: absolute;
  right: 0;
  top: 11px;
  z-index: 10;
  @include mqmedish {
    display: block;
  }
  @include mqhuge {
    top: 13px;
  }
  svg {
    display: block;
    overflow: visible;
    width: 135px;
    height: auto;
    @include mqbig {
      width: 150px;
    }
    @include mqhuge {
      width: 191px;
    }
  }
  .arrow {
    transition: transform 0.3s $easeInOutSine;
  }
  &:hover .arrow {
    transform: translateX(10px);
  }
}

#page-header:target, .hasOpenMenu {
  transform: none !important;
}

.hasOpenMenu {
  // height: 640px !important;
  position: absolute;
  width: 100%;
  height: 100%;
}

.-input-focused {
  // position: fixed;
  // height: 100%;
  // overflow: hidden;
  // width: 100%;
  .menu__items {
    // position: absolute;
    // top:0;

  }
  .menu__item {
    // opacity: 0 !important;
    pointer-events: none;
  }
}
.-has-open-menu {

  // position: fixed;
  // position: absolute;
  // height: 100%;
  // overflow: hidden;
  // width: 100%;
  pointer-events: none;
  .menu,.search,.search__result {
    pointer-events: all;
  }

  body {
    // position: fixed;
    // border: 10px solid red;
    // margin-top: 0;
    // overflow: scroll !important;
  }
}


.menu {
  // margin-top: 0;
  // opacity: 0 !important;
  // border: 1px solid red;
  background: $black;
  // background: rgba(0,0,0,0.5);
  position:fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  top:0;
  left:0;
  z-index: 999999;
  // top: auto;
  // bottom: 0;
  // height: 640px !important;
  @include mqmedish {
    overflow: visible;
    text-align: center;
    position: absolute;
    height: auto;
    background: transparent;
    width: 100%;
    padding: 0 157px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  #page-header:target & {
    height: 100%;
  }

  a {
    color: #fff;
    @include mqmedish {
      color: $black;
    }
  }

  &__wrapper {
    position: relative;
    width:100%;
    height:100%;
    padding: 22px $bodyGutterSmall;
    z-index: 99;
    @include mqmed {
      padding: 44px $bodyGutterMed;
    }
    @include mqmedish {
      padding: 0;
      width: auto;
      height: auto;

    }
    @include mqhuge {
      padding: 44px $bodyGutterHuge;
    }
  }

  &__items {
    padding-top: (110px - 22px);
    @include mqmed {

      padding: 0;
      margin-top: 50px;

    }
    @include mqmedish {
      position: relative;

      margin: 0;
    }
  }

  &__item {
    display: block;
    margin: 0 0 7px;
    @include mqmedish {
      display: inline-block;
      margin: 0 6px;
    }
    @include mqbig {
      margin: 0 9px;
    }
    a {
      -webkit-font-smoothing: antialiased;
      line-height: 1.25;
      display: inline-block;
      box-shadow: inset 0 -1px 0 transparentize($black, 1);
      transition: box-shadow 0.4s $easeOutSine;
      font-size: 25px;
      @extend %heading;
      @include mqmed {
        font-size: 33px;

      }
      @include mqmedish {
        font-family:"Patron", 'Helvetica Neue', Arial, Droid, sans-serif;
        font-weight:normal;
        font-size: 15px;
      }
      @include mqbig {
        font-size: 18px;
      }
      @include mqhuge {
        font-size: 21px;
      }

      &:hover {
        box-shadow: inset 0 -1px 0 transparentize($black, 0);
      }

    }
  }


}

.search {
  // height: 100%;
  display: none;
  position: absolute;
  // -webkit-box-align:center;
  // position: relative;
  z-index: 9999999;
  width: 100%;
  padding: 0 25px;
  left: 0;
  // bottom: 40px;
  top: 400px;
  // transform:translateY(-150px);
  // top:100%;
  @include mqmed {
    padding: 0 50px;
  }
  @include mqmedish {
    transform: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    background: $black;
    height: 100%;
    z-index: 11;
    padding: 0 ($bodyGutterMed+25) 0 $bodyGutterMed;
  }
  @include mqbig {
    padding: 0 ($bodyGutterBig+25) 0 $bodyGutterBig;
  }
  &__form-wrapper {
    position: relative;
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid #fff;
    @include mqmedish {
      padding-top: 0;
      border-top: none;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__close {
    &--bar {
      display: none;
      @include mqmedish {
        display: block;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%) rotateZ(0deg) ;
        transform-origin: center center;
        transition: transform 0.75s cubic-bezier(0.860, 0.000, 0.070, 1.000);
        width: 20px;
        height: 20px;
      }
      &:hover {

        transform: translateY(-50%) rotateZ(360deg) ;
      }
      .search__close.-x {
        background: #fff;
      }
    }
    svg {
      width: 15px;
      height: 16px;
      display: block;
    }
  }

  &__input {
    border: none;
    background: $black;
    color: #fff;
    width: 100%;
    &:focus {
      outline-color: $black;
    }
    &::-webkit-input-placeholder {
      color: #fff;
    }
    &:-moz-placeholder {
     color: #fff;
    }
    &::-moz-placeholder {
     color: #fff;
    }
    &:-ms-input-placeholder {
     color: #fff;
    }
    @extend %heading;
    font-size: 25px;
    @include mqmed {
      font-size: 33px;
    }
    @include mqmedish {
      font-size: 29px;
    }
    .search-standalone & {
      color: #000;
      background: #fff;
        &::-webkit-input-placeholder {
          color: #000;
        }
        &:-moz-placeholder {
         color: #000;
        }
        &::-moz-placeholder {
         color: #000;
        }
        &:-ms-input-placeholder {
         color: #000;
        }
    }
  }
  &__button {
    @extend .visuallyhidden;
  }

}

.open-menu,
.close-menu {
  @extend %bodyText;
  line-height: 1;
}

.open-menu {
  border: 1px solid $black;
  padding: 9px 24px;

  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateZ(0);
  line-height: 1;
  transition: color 0.35s, background 0.35s;
  @include mqmed {
    padding: 13px 24px;
  }
  @include mqmedish {
    display: none;
  }
  &:hover {
    background: $black;
    color: #fff;
  }
}
.open-search {
  @extend %bodyText;
  display: none;
  transition: color 0.35s, background 0.35s;
  @include mqmedish {
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px;
    svg {
      position: relative;
      width: auto;
      height: 16px;
      top: 2px;
    }
  }
  @include mqbig {
    svg {
      height: 18px;
      top: 1px;
    }
  }
  @include mqhuge {
    margin: 0 9px;
    svg {
      height: 21px;
      top: 0;
    }
  }
  &:hover {
    box-shadow: none !important;
  }
}

.close-menu {
  display: block;
  float: right;
  clear:both;
  border: 1px solid #fff;
  padding: 9px 10px;
  position: relative;
  z-index: 1;
  @include mqmedish {
    display: none;
  }
}
