%center {
  margin-left: auto;
  margin-right: auto;
}

%clearfix:before, %clearfix:after { content: ""; display: table; }
%clearfix:after { clear: both; }
%clearfix { *zoom: 1; }

%bodyGutter {
  padding: 0 $bodyGutterSmall;
  @include mqmed {
    padding: 0 $bodyGutterMed;
  }
  @include mqbig {
    padding: 0 $bodyGutterBig;
  }
  @include mqhuge {
    padding: 0 $bodyGutterHuge;
  }
}

%bodyGutterLeft {
  padding-left: $bodyGutterSmall;
  @include mqmed {
    padding-left: $bodyGutterMed;
  }
  @include mqbig {
    padding-left: $bodyGutterBig;
  }
  @include mqhuge {
    padding-left: $bodyGutterHuge;
  }
}

%bodyGutterRight {
  padding-right: $bodyGutterSmall;
  @include mqmed {
    padding-right: $bodyGutterMed;
  }
  @include mqbig {
    padding-right: $bodyGutterBig;
  }
  @include mqhuge {
    padding-right: $bodyGutterHuge;
  }
}

%margin {
  margin-bottom: $marginSmall;
  @include mqmed {
    margin-bottom: $marginMed;
  }
}

%margin2 {
  margin-bottom: $marginSmall2;
  @include mqmed {
    margin-bottom: $marginMed2;
  }
  @include mqbig {
    margin-bottom: $marginBig2;
  }
}

%gridWrap {
  @extend %clearfix;
  padding: 0 $bodyGutterSmall;
  @include mqmed {
    padding: 0 ($bodyGutterMed - ($gridGutterMed/2));
  }
  @include mqbig {
    padding: 0 ($bodyGutterBig - ($gridGutterBig/2));
  }
  @include mqhuge {
    padding: 0 ($bodyGutterHuge - ($gridGutterHuge/2));
  }
}

%word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
