/*!
* Styles for Værsågod by Værsågod, vaersaagod.no
*
* @author Isaac Gray
*/

// Import some stuff that makes things tick

// Sass files in which order is important
@import "partials/variables";
@import "partials/easings";
@import "partials/normalize";
@import "partials/mixins";
@import "partials/placeholders";
@import "partials/typography";
@import "partials/basicsetup";
@import "partials/helpers";

@import "includes/general";
@import "includes/blocks/cookiebanner";
@import "includes/blocks/footer";
@import "includes/blocks/header";
@import "includes/blocks/linkedentries";
@import "includes/pages/archive";
@import "includes/pages/article";
@import "includes/pages/contact";
@import "includes/pages/front";
@import "includes/pages/page";
@import "includes/pages/search";

/*
.dev-grid {
	position: fixed;
	z-index: 999999999999;
	top: 0;
	left: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;
  @extend %bodyGutter;
  // padding-right: 55px;
  @include mqmed {
    padding-right: $bodyGutterMed - $gridGutterMed;
  }
  @include mqbig {
    padding-right: $bodyGutterBig - $gridGutterBig;
  }
  @include mqhuge {
    padding-right: $bodyGutterHuge - $gridGutterHuge;
  }
  // margin-right: -40px;
}

.dev-grid-col {

	width: 100%;
  height: 100%;
  position: relative;
  display: block;
  float: left;
  &:after {
    content:"";
    width: 100%;
    height: 100%;
    background: rgba(236,28,36, 0.2);
    position: absolute;
    top: 0;
    left: 0;
  }
  @include mqmed {
    width: 50%;
    border-right: $gridGutterMed solid transparent;
  }
  @include mqbig {
    width: 16.666%;
    border-right: $gridGutterBig solid transparent;
  }
  @include mqhuge {
    width: 16.666%;
    border-right: $gridGutterHuge solid transparent;
  }
}

.dev-grid-col-3, .dev-grid-col-4, .dev-grid-col-5, .dev-grid-col-6 {
  display: none;
  @include mqbig {
    display: block;
  }
}
*/
