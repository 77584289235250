.linked {
    &__no-image {
        width: 100%;
        height: 0;
        padding-bottom: 66.25%;
        background: #f2caaa;
        opacity: 0.4;
        @include mqmedish {
            padding-bottom: 66.429%;
        }
        @include mqbig {
            padding-bottom: 66.25%;
        }
        @include mqhuge {
            padding-bottom: 66.4%;
        }
    }

    @extend %gridWrap;

    &.-feature {
        position: relative;
    }

    &__more {
        @extend %bodyText;
    }

    &__item {

        > a {
          display: block;
        }

        transform: translateZ(0);
        @include spacing(25, 50, 80, 100);
        width: 100%;
        text-align: center;


        @include mqmed {
            float: left;
            width: 50%;
            padding: 0 $gridGutterMed/2;
        }

        @include mqbig {
            padding: 0 $gridGutterBig/2;
        }
        @include mqhuge {
            padding: 0 $gridGutterHuge/2;
        }

        &.-two-col {

        }

        &.-three-col {
            .-sub &:last-child {
                display: none;
                @include mqmedish {
                    display: block;
                }
            }

            .related &:nth-child(4) {
                @include mqmedish {
                    display: none;
                }
            }

            @include mqmedish {
                width: 33.333%;
            }

            &:nth-child(2n+3) {
                @include mqmed {
                    clear: both;
                }
                @include mqmedish {
                    clear: none;
                }
            }

            &:nth-child(3n+4) {
                @include mqmedish {
                    clear: both;
                }
            }
        }

        &.-press {
            @include spacing(25, 40, 50, 60);
            flex: 0 0 auto;
            display: flex;

            a {
                flex: auto;
                border: 1px solid $black;
                display: block;
                padding: 50px;
                position: relative;
                -webkit-font-smoothing: antialiased;
                transition: background 0.35s ease-out, color 0.15s ease-out;

                &:hover {
                    color: #fff;
                    background: $black;

                    path {
                        fill: #fff;
                    }

                    .linked__press-arrow {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }

    &__press-arrow {
        display: block;
        text-align: center;
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        transition: transform 0.35s ease-out;
        @include mqbig {
            font-size: 16px;
        }

        svg {
            width: 1em;
            height: 0.5em;
        }

        path {
            transition: fill 0.15s ease-out;
        }
    }

    &.-press {
        @include mqmed {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
        }
    }

    &__image {
        @include spacing(25, 25, 50, 60, 5);
        position: relative;
        overflow: hidden;

        &-overflow-wrap {
            overflow: hidden;
            position: relative;
        }

        &.-no-overflow {
            overflow: visible;
        }

        img {
            display: block;
            transform: translateZ(0);
        }


    }

    &__overlay {
        z-index: 1;
        background: $peach;
        display: block;
        opacity: 0;
        position: absolute;
        left: -1%;
        top: -1%;
        width: 102%;
        height: 102%;
        transition: opacity 0.35s $easeOutSine;

        .not-using-touch a:hover & {
            opacity: 0.9;
        }

    }

    &__title {
        @extend %headingSNoMargin;
        @include spacing(25, 25, 25, 30, 12);
    }

    &__description {
        @extend %bodyText;
        @include spacing(25, 25, 25, 30, 5);
        display: block;

        .-two-col & {
            margin-left: auto;
            margin-right: auto;
            max-width: 480px;
        }
    }

    &__external {
        display: block;
        @extend %bodyText;
        text-align: center;
        @include spacing(15, 20, 25, 30, 5);
        margin-top: -5px;
        span {
            display: inline-block;
            padding-left: 5px;
            position: relative;
            top: -1px;
            transition: transform 0.35s ease-out;
        }
        svg {
            width: 0.666em;
            height: 0.666em;
            path {
                fill: currentColor;
            }
        }
        a:hover & {
            span {
                transform: translateX(3px) translateY(-3px);
            }
        }
    }

    &__category {
        transform: translateZ(0);
        @extend %bodyText;
        line-height: 1;
        display: inline-block;
        padding: 9px 10px;
        border: 1px solid $black;
    }

    &__publication {
        @extend %bodyText;
        line-height: 1;
        display: inline-block;
        box-shadow: inset 0 -1px 0 $black;
        padding-bottom: 5px;
        transition: box-shadow 0.35s $easeOutSine;

        .not-using-touch a:hover & {
            box-shadow: inset 0 -1px 0 transparentize($black, 1);
        }
    }

    &__small-arrow {
        margin-left: 5px;
        transition: transform 0.35s $easeOutSine;
        display: inline-block;

        .not-using-touch a:hover & {
            transform: translateX(5px);
        }

        @include mqbig {
            font-size: 16px;
        }

        svg {
            width: 1em;
            height: 0.5em;
        }
    }

    a {
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    &__read-more {
        position: absolute;
        top: 30px;
        left: 30px;
        transform: translateX(-10px);
        // transition: transform 0.35s $easeOutSine 0.15s, opacity 0.35s $easeOutSine 0.15s;
        transition: transform 0.35s $easeOutSine;
        // opacity: 0;
        .-two-col & {
            top: 40px;
            left: 40px;
        }

        a:hover & {
            transform: translateX(0);
            // opacity: 1;
        }

        @extend %headingSNoMargin;

        &-text {

        }

        &-arrow {
            display: block;
            text-align: left;
            @include mqbig {
                font-size: 16px;
            }

            svg {
                width: 1em;
                height: 0.5em;
            }
        }

    }

}
