.page-contact-details {
  @extend %bodyGutter;
  @include spacing(40,80,100,110,7);
  @extend %heading;
  text-align: center;
  &__heading {
    font-size: 25px;
    line-height: 1.25;
    @include mqmed {
      font-size: 33px;
    }
    @include mqbig {
      font-size: 45px;
    }
    @include mqhuge {
      font-size: 60px;
    }
  }

  &__detail {
    font-size: 18px;
    line-height: 1.25;
    display: block;
    @include mqmed {
      font-size: 26px;
    }
    @include mqbig {
      font-size: 32px;
    }
    @include mqhuge {
      font-size: 37px;
    }
    &.-map {
      margin-bottom: 40px;
      a {
        box-shadow: inset 0 -2px 0 transparentize($black,0);
        transition: box-shadow 0.35s ease-out;
        &:hover {
          box-shadow: inset 0 -2px 0 transparentize($black,1);
        }
      }
    }
    &.-phone {
      a {
        box-shadow: inset 0 -2px 0 transparentize($black,1);
        transition: box-shadow 0.35s ease-out;
        &:hover {
          box-shadow: inset 0 -2px 0 transparentize($black,0);
        }
      }
    }
    &.-email {
      a {
        box-shadow: inset 0 -2px 0 transparentize($black,1);
        transition: box-shadow 0.35s ease-out;
        &:hover {
          box-shadow: inset 0 -2px 0 transparentize($black,0);
        }
      }
    }
  }
}

.page-contact-people {
  padding: 0 $bodyGutterSmall;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  @include mqmed {
    padding: 0 $bodyGutterMed - $gridGutterMed/2;
  }
  @include mqbig {
    padding: 0 $bodyGutterBig - $gridGutterBig/2;
  }
  @include mqhuge {
    padding: 0 $bodyGutterHuge - $gridGutterHuge/2;
  }
  
  &__person {
    @include spacing(40,80,100,110,10);
    width: 100%;
    text-align: center;
    img {
      width: 100%;
      height: auto;
      @include spacing(25,30,40,45);
    }
    
    @include mqmed {
      width: 50%;
      padding: 0 $gridGutterMed/2;
      
    }
    @include mqbig {
      padding: 0 $gridGutterBig/2;
    }
    @include mqhuge {
      padding: 0 $gridGutterHuge/2;
    }
  }
  &__person-name {
    @extend %heading;
    @include spacing(10,10,15,15);
    display: block;
    font-size: 18px;
    line-height: 1;
    @include mqbig {
      font-size: 32px;
    }
    @include mqhuge {
      font-size: 37px;
    }
  }

  &__person-role {
    @include spacing(25,30,40,45,10);
    //text-transform: uppercase;
    display: block;
    font-size: 11px;
    line-height: 1.46;
    letter-spacing: 2px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    
    @include mqbig {
      font-size: 14px;
    }
    @include mqhuge {
      font-size: 17px;
    }
  }

  &__person-contact {
    @extend %bodyText;
    display: block;
    a {
      box-shadow: inset 0 -1px 0 transparentize($black,1);
      transition: box-shadow 0.35s ease-out;
      &:hover {
        box-shadow: inset 0 -1px 0 transparentize($black,0);
      }
    }
  }
}

.page-contact-text {
  background: $peach2;
  @extend %bodyGutter;
  @include spacing(40,80,100,110,10,'padding-top','padding-bottom');
  &__inner {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
  }
  &__sub-heading {
    @extend %heading;
    font-size: 18px;
    line-height: 1.25;
    display: block;
    margin-bottom: 1em;
    margin-top: 1.5em;
    @include mqmed {
      font-size: 26px;
    }
    @include mqbig {
      font-size: 32px;
    }
    @include mqhuge {
      font-size: 37px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    @extend %articleText;
    a {
      box-shadow: inset 0 -1px 0 transparentize($black,0);
      transition: box-shadow 0.35s ease-out;
      &:hover {
        box-shadow: inset 0 -1px 0 transparentize($black,1);
      }
    }
  }
}
