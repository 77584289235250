.footer {
  background: $black;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  position: relative;
  padding-top: 90px;
  @extend %bodyGutter;
  @include mqmed {
    padding-top: 80px;
  }
  @include mqbig {
    padding-top: 100px;
  }
  @include mqhuge {
    padding-top: 90px;
  }
  a {
    color: #fff;
  }
  &__title {
    text-align: center;
    @extend %headingSNoMargin;
    @include mqsmallmax {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    .csstransforms & {
      line-height: 1;
      position: absolute;
      left: $bodyGutterMed/2;
      top: 50%;
      transform: rotate(-90deg) translateY(-50%);
      transform-origin: center center;
      z-index: 99;
      width: 1px;
      height: 1px;
      @include mqbig {
        left: $bodyGutterBig/2;
      }
      @include mqhuge {
        left: $bodyGutterHuge/2;
      }
      span {
        white-space: nowrap;
        display: block;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

    }
  }

  &__social {
    text-align: center;
    a {
      margin: 0 10px;
      @extend %bodyText;
      padding-bottom: 4px;
      box-shadow: inset 0 -1px 0 #fff;
      transition: box-shadow 0.35s $easeOutSine;
      &:hover {
        box-shadow: inset 0 -1px 0 transparentize(#fff,1);
        span {
          transform: translateX(3px) translateY(-3px);
        }
      }
    }
    span {
      display: inline-block;
      padding-left: 5px;
      position: relative;
      top: -1px;
      transition: transform 0.35s ease-out;
    }
    svg {
      width: 0.666em;
      height: 0.666em;
    }
  }

  &__back-to-top {
    text-align: center;
    @extend %headingSNoMargin;
    .csstransforms & {
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      @include mqmed {
        left: auto;


        line-height: 1;
        position: absolute;
        right: $bodyGutterMed/2;
        top: 50%;
        transform: rotate(90deg) translateY(-50%);
        transform-origin: center center;
        z-index: 99;
        width: 1px;
        height: 1px;
      }
      @include mqbig {
        right: $bodyGutterBig/2;
      }
      @include mqhuge {
        right: $bodyGutterHuge/2;
      }



    }
    &:hover {
      .csstransforms & {
        @include mqmed {
          .footer__back-to-top-arrow {
            transform: translateX(-5px);
          }
        }
      }
    }
    &-wrap {
      @include mqsmallmax {
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid #fff;
        padding: 5px 10px;
        border-radius: 30px;
      }
      .csstransforms & {
        @include mqmed {
          white-space: nowrap;
          display: block;
          position: absolute;
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }
    &-text{
      @include mqsmallmax {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      @include mqmed {
        padding-left: 10px;
      }
    }
    &-arrow {
      display: none;
      .csstransforms & {
        @include mqsmallmax {
          display: block;
          transform: rotate(90deg);
        }
        @include mqmed {
          display: inline-block;
          transition: transform 0.35s;
        }
      }
    }
    svg {
      width: 1em;
      height: 0.5em;
      @include mqsmallmax {
        display: block;
      }
    }
    path {
      fill: #fff;
    }
  }
  &__subheading {
    @extend %headingS;
  }
  &__contact {
    text-align: center;
  }
  &__contact-item {
    @extend %bodyText;
    display: block;
    &:last-child {
      @include spacing(40,40,50,60,7,'margin-bottom');
    }
    &.-email {
      margin-bottom: 4px;
      a {
        box-shadow: inset 0 -1px 0 #fff;
        transition: box-shadow 0.35s $easeOutSine;
        &:hover {
          box-shadow: inset 0 -1px 0 transparentize(#fff,1);
        }
      }
    }
  }

  &__credit {
    @extend %bodyText;
    text-align: center;
    line-height: 1;
    padding: 15px 0 25px;
    color: $grey;
    @include mqmed {
      padding: 20px 0 25px;
    }
    @include mqbig {
      padding: 40px 0 30px;
    }
    @include mqhuge {
      padding: 40px 0 60px;
    }
    a {
      color: $grey;
      transition: color 0.35s $easeOutSine;
      &:hover {
        color: #fff;
      }
    }
  }
  
  &__text {
    font-size: 12px;
    line-height: (19/12);
    max-width: 600px;
    
    @include mqbig {
      font-size: 15px;
      line-height: (22/15);
      max-width: 800px;
    }
    
    text-align: center;
    padding: 40px 0 25px;
    
    margin-left: auto;
    margin-right: auto;
    @include mqmed {
      padding: 60px 0 25px;
    }
    @include mqbig {
      padding: 80px 0 30px;
    }
    @include mqhuge {
      padding: 80px 0 60px;
    }
    a {
        box-shadow: inset 0 -1px 0 #fff;
        transition: box-shadow 0.35s $easeOutSine;
        &:hover {
          box-shadow: inset 0 -1px 0 transparentize(#fff,1);
        }
      }
  }
  
  &__form {
    color: #fff;
    text-align: center;
    @include spacing(33,33,43,53,0,'margin-bottom');
    position: relative;
    &-response {
      @extend %bodyText;
      position: absolute;
      bottom: -30px;
      width: 100%;
      text-align: center;
      opacity: 0;
      &.-error {
        color: red;
      }
    }
    label {
      border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
    input {
      display: inline-block;
      vertical-align: top;
      @extend %bodyText;
      line-height: 1;
      background: $black;
      border: 1px solid #fff;
      color: #fff;
      &::-webkit-input-placeholder {
        color: #fff;
        line-height: 1;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #fff;
        line-height: 1;
      }
      &::-moz-placeholder {  /* Firefox 19+ */
        color: #fff;
        line-height: 1;
      }
      &:-ms-input-placeholder {
        color: #fff;
        line-height: 1;
      }
    }
  }
  &__input-wrap {
    .flexbox & {
      display: flex;
      width: 100%;
      max-width: 370px;
      margin: 0 auto;
      @include mqbig {
        max-width: 400px;
      }
      @include mqhuge {
        max-width: 490px;
      }
    }
  }
  &__input {
    padding: 18px 10px 17px;
    padding: 22px 20px 20px;
    @include mqmed {
      min-width: 300px;
    }
    @include mqhuge {
      padding: 26px 20px 22px;
    }
    .flexbox & {
      flex: 1;
    }
  }
  &__button {
    padding: 18px 10px 17px;
    padding: 22px 30px 20px;
    transition: color 0.25s ease-out, background 0.25s ease-out;
    @include mqhuge {
      padding: 26px 30px 22px;
    }
    &:hover {
      background: #fff;
      color: #000;
    }
    .flexbox & {
      border-left: none;
    }
  }
}
